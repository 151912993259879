import React, { useState } from "react";
import "./App.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as dayjs from 'dayjs'

export default function App() {

  const recaptchaRef = React.createRef();

  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState(null);
  const [prescriptionNumber, setPNumber] = useState("");
  const [orderNumber, setONumber] = useState("");
  const [shippingPostalCode, setPsNumber] = useState("");
  const handleSubmit = async () => {
    //const token = await recaptchaRef.current.executeAsync();
    const recaptchaValue = recaptchaRef.current.getValue();
    if(recaptchaValue == '')
    {
      toast.error("Please fill reCAPTCHA.")
    }
    else {
      //console.log(recaptchaValue);
      if(recaptchaValue != '' && (prescriptionNumber || orderNumber) && shippingPostalCode){
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

      let obj = {};
      if(prescriptionNumber) obj.prescriptionNumber =prescriptionNumber;
      if(orderNumber) obj.orderNumber =orderNumber;
      if(shippingPostalCode) obj.shippingPostalCode =shippingPostalCode;

      const req = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(obj),
        redirect: 'follow'
      };
        try {
          const response = await fetch("https://api.harrow.cloud/v1/grx/tracking/get-tracking-information", req)
          const res  = await response.json()
          const status = response?.status;
          if(status === 201){
            setResult(res[0])
            setShowResult(true);
          }else{
            toast.error(res?.message)
          }
        }catch (e) {
          toast.error("Something went wrong!")
        }
      // clear();
      }
      else{
        toast.error("Please fill required fields.")
      }
    }

  };
  const clear = () => {
    setONumber("");
    setPNumber("");
    setPsNumber("");
    setShowResult(false);
    window.location.reload(false);
  };

  const onChange = (e)=>{
    //console.log("vin");
    //console.log(e);
  }

  return (
    <>
     <ToastContainer />
      <div className="page-wrap">
        <div className="trackingpage-container">
          <header className="header-logo">
            <img
              src="/trackingpage-imprimis-logo.png"
              width={687}
              height={136}
            />
          </header>
          <div className="map">
            <img src="/trackingpage-map-1.png" width={1158} height={515} />
          </div>
          {showResult ? (
            <>
              <div className="content-area">
                <h1>Tracking Details:</h1>
              </div>
              <div className="content-area">
                <div className="form-box-full">
                  <p>
                    Order: <span>{result?.invoice_nbr}</span>
                  </p>
                  <p>
                    Prescription: <span>{result?.rx_id}</span>
                  </p>
                  <p>
                    Status: <span>{result?.trackingUrl?'Shipped':'Processing'}</span>
                  </p>
                  <p>
                    Ship Date: <span>{dayjs(result?.ship_date).format('YYYY-MM-DD')}</span>
                  </p>
                  {
                    result?.trackingUrl &&  <p>
                    Tracking:
                    <a
                      target="_blank"
                      href={result?.trackingUrl}
                    >
                      {result?.tracking_code}
                    </a>
                  </p>
                  }

                </div>
              </div>
              <button className="button" onClick={clear} type="button">
                Track Another
              </button>
            </>
          ) : (
            <div className="content-area">
              <p>
                Follow your recent prescription from ImprimisRx to your
                doorstep!
              </p>
              <h1>Track My Order</h1>
              <div className="form-box">
                <div className="form-box-top">
                  <p>
                    Please enter your prescription Rx number or your order
                    number along with your shipping zip code below.
                  </p>
                </div>
                <div className="form-box-bluebg">
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={10}
                    onChange={({ currentTarget }) =>
                      setPNumber(currentTarget.value)
                    }
                    value={prescriptionNumber}
                    type="text"
                    placeholder="Prescription Number"
                    name="r"
                  />
                  <p>OR</p>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={10}
                    onChange={({ currentTarget }) =>
                      setONumber(currentTarget.value)
                    }
                    value={orderNumber}
                    type="text"
                    placeholder="Order Number"
                    name="o"
                  />
                </div>
                <div className="form-box-bottom">
                  <p>AND</p>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={5}
                    onChange={({ currentTarget }) =>
                      setPsNumber(currentTarget.value)
                    }
                    value={shippingPostalCode}
                    type="text"
                    placeholder="Shipping Postal Code"
                    name="p"
                  />
                </div>
              </div>
              <div className="form-box2">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LdGRv8jAAAAAM0CUwQoPfukw2BMkK4iSEG8McdS"
                  onChange={onChange}
                />
              </div>
              <button className="button" type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
      <footer className="site-footer">
        <p>© 2021 ImprimisRx. All Rights Reserved.</p>
        <div className="container">
          <ul className="list">
            <li>
              <a
                href="https://imprimisrx.com/privacy-policy/"
                target="_new"
                className="ftr-link"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.imprimisrx.com/assets/IMMY-Notice-of-Privacy-Practices-HIPAA.pdf"
                target="_blank"
                className="ftr-link"
              >
                HIPAA Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://imprimisrx.com/social-media-policy/"
                target="_new"
                className="ftr-link"
              >
                Social Media Policy
              </a>
            </li>
            <li>
              <a
                href="https://privacyportal.onetrust.com/webform/26d529a1-2179-4e18-982b-fab75adf84d4/fc8a4d3b-4d1c-4240-9700-81df2a92e331"
                target="_blank"
                className="ftr-link"
              >
                Exercise Your Privacy Rights
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
